<template>
    <div v-if="isLoaded" class="permission-create">
        <div class="modal-dialog modal-dialog--access block block-mode-loading-refresh">
            <div class="modal-content">
                <div class="modal-header modal-header_no-border">
                    <h3 class="block-title">{{title}}</h3>
                    <label class="material-toggle">
                        <input
                            v-if="rubricID"
                            v-model="data.parentRight"
                            @change="copyParentPermission"
                            type="checkbox"
                            class="material-toggle__input"
                            checked="checked"
                        >

                        <input
                            v-else
                            v-model="data.access_all"
                            @change="isBlock = !isBlock"
                            type="checkbox"
                            class="material-toggle__input"
                            checked="checked"
                        >

                        <span class="material-toggle__icon"></span>
                        <span class="material-toggle__text">{{checkbox}}</span>
                    </label>

                    <button
                        type="button"
                        class="btn-block-option close-modal"
                        @click="$modal.hide('ModalPermissionCreate')"
                    >
                        ×
                    </button>
                </div>
                <div class="block-content content modal-staff">
                    <div class="workers__desc">
                        {{text}}
                    </div>

                    <div :class="['row', { 'area_lock': this.isBlock }]">
                        <div class="col-lg-6 workers">
                            <div class="workers__col">
                                <div class="workers__head">
                                    <ul class="material-header__nav nav" role="tablist">
                                        <li class="nav-item">
                                            <a id="vue-access-department-tab" data-toggle="tab" @click="data.isVisibleTab=true"
                                               v-bind:class="{active: data.isVisibleTab}">Общее</a>
                                        </li>
                                        <li class="nav-item" v-if="showUsersTab">
                                            <a id="vue-access-workers-tab"
                                               v-bind:class="{active: !data.isVisibleTab}" @click="data.isVisibleTab=false">Сотрудники</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="workers__body">
                                    <div class="tab-content">
                                        <div class="tab-pane fade" id="vue-access-department" v-bind:class="{'active show': data.isVisibleTab}"
                                             role="tabpanel" aria-labelledby="material-tab">
                                            <div class="workers__search form-group">
                                                <svg width="13" height="13" viewBox="0 0 13 13"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M2.16406 5.55739C2.16406 3.68324 3.68336 2.16394 5.55751 2.16394C7.43166 2.16394 8.95096 3.68324 8.95096 5.55739C8.95096 7.43154 7.43166 8.95084 5.55751 8.95084C3.68336 8.95084 2.16406 7.43154 2.16406 5.55739ZM5.55751 0.66394C2.85493 0.66394 0.664062 2.85481 0.664062 5.55739C0.664062 8.25997 2.85493 10.4508 5.55751 10.4508C6.63818 10.4508 7.63702 10.1005 8.44669 9.50729L11.5757 12.6364C11.8686 12.9293 12.3435 12.9293 12.6364 12.6364C12.9293 12.3435 12.9293 11.8686 12.6364 11.5757L9.50736 8.44664C10.1006 7.63696 10.451 6.63808 10.451 5.55739C10.451 2.85481 8.26009 0.66394 5.55751 0.66394Z"></path>
                                                </svg>
                                                <input v-model="data.searchStructure" type="text" class="form-control round" placeholder="Поиск">
                                            </div>
                                            <div class="workers__tree">
                                                <ul class="workers__department department js-vue-department-tree">
                                                    <permission-tree-structure @buildStructTree="clickStructure" @buildPositionTree="clickPosition" v-for="(item, index) in data.leftTree.structure" :item="item" :key="item.id" :leftTree="true"></permission-tree-structure>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="vue-access-workers" role="tabpanel"
                                             v-bind:class="{'active show': !data.isVisibleTab}"
                                             aria-labelledby="access-tab">
                                            <div class="workers__search form-group">
                                                <svg width="13" height="13" viewBox="0 0 13 13"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M2.16406 5.55739C2.16406 3.68324 3.68336 2.16394 5.55751 2.16394C7.43166 2.16394 8.95096 3.68324 8.95096 5.55739C8.95096 7.43154 7.43166 8.95084 5.55751 8.95084C3.68336 8.95084 2.16406 7.43154 2.16406 5.55739ZM5.55751 0.66394C2.85493 0.66394 0.664062 2.85481 0.664062 5.55739C0.664062 8.25997 2.85493 10.4508 5.55751 10.4508C6.63818 10.4508 7.63702 10.1005 8.44669 9.50729L11.5757 12.6364C11.8686 12.9293 12.3435 12.9293 12.6364 12.6364C12.9293 12.3435 12.9293 11.8686 12.6364 11.5757L9.50736 8.44664C10.1006 7.63696 10.451 6.63808 10.451 5.55739C10.451 2.85481 8.26009 0.66394 5.55751 0.66394Z"></path>
                                                </svg>
                                                <input v-model="data.searchUsers" type="text" class="form-control round"
                                                       placeholder="Поиск">
                                            </div>
                                            <div class="workers__tree">
                                                <ul class="workers__department department">
                                                    <li class="department__item open" v-for="(item, index) in filteredUsers" :key="index">
                                                        <div class="department__row" >
                                                            <span class="department__row_name">{{ item.full_name }}</span>
                                                            <label>
                                                                <input
                                                                    :checked="item.checked"
                                                                    type="checkbox" class="access_departament" :data-id="'original-worker-' + item.id" @click="leftTreeUsersBuild(item)">
                                                                <div class="department__row_button">
                                                                    <i v-if="item.checked" class="fas fa-check"></i>
                                                                    <i v-else class="fas fa-plus"></i>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 workers">
                            <form class="workers__col">
                                <div class="workers__head">
                                    <ul class="material-header__nav nav" role="tablist">
                                        <li class="nav-item">
                                            <a id="vue-sel-access-department-tab" data-toggle="tab"
                                               v-bind:class="{active: data.isVisibleTab}" @click="data.isVisibleTab=true"
                                               href="#vue-sel-access-department" role="tab"
                                               aria-controls="material" aria-selected="true" style="">
                                                Общее
                                                <span class="nav-item__numb">{{ data.rightTree.structure.length }}</span>
                                            </a>
                                        </li>
                                        <li class="nav-item" v-if="showUsersTab">
                                            <a id="vue-sel-access-workers-tab" data-toggle="tab"
                                               v-bind:class="{active: !data.isVisibleTab}" @click="data.isVisibleTab=false"
                                               href="#vue-sel-access-workers" role="tab"
                                               aria-controls="access" aria-selected="false">
                                                Сотрудники
                                                <span class="nav-item__numb">{{ data.rightTree.users.length }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="workers__body">
                                    <div class="tab-content">
                                        <div class="tab-pane fade" id="vue-sel-access-department"
                                             v-bind:class="{'active show': data.isVisibleTab}"
                                             role="tabpanel" aria-labelledby="material-tab">
                                            <div class="workers__tree">
                                                <ul class="workers__department department">
                                                    <permission-tree-structure @buildStructTree="clickStructure" @buildPositionTree="clickPosition" v-for="(item, index) in data.rightTree.structure" :item="item" :key="item.id"></permission-tree-structure>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="vue-sel-access-workers" role="tabpanel"
                                             v-bind:class="{'active show': !data.isVisibleTab}"
                                             aria-labelledby="access-tab">
                                            <div class="workers__list">
                                                <ul class="workers__department department">
                                                    <li class="department__item open" v-for="(item, index) in data.rightTree.users" :key="index">
                                                        <div class="department__row">
                                                            <span class="department__row_name">{{ item.full_name }}</span>
                                                            <label>
                                                                <input type="checkbox" class="access_departament" :data-id="'worker-' + item.id" @click="rightTreeUsersBuild(item)" :checked="item.checked">
                                                                <div class="department__row_button">
                                                                    <i v-if="item.checked" class="fas fa-check"></i>
                                                                    <i v-else class="fas fa-plus"></i>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="module-set__footer">
                        <button type="button" class="v-btn" @click="onSubmits">Сохранить</button>
                        <button class="v-btn v-btn--transparent" @click="$modal.hide('ModalPermissionCreate')">Отменить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <loading-spinner v-else/>
</template>

<script>
import session from '@/api/session';
import PermissionTreeStructure from "./PermissionTreeStructure";
import {basicMethods} from './basic_methods';
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
    name: 'ModalPermissionCreate',
    mixins: [
        basicMethods
    ],
    components: {
        LoadingSpinner,
        PermissionTreeStructure
    },

    data() {
        return {
            isLoaded: false,
            isBlock: false
        }
    },
    props: {
        showUsersTab: {
            required: false,
            default: true
        },
        dataCache: {
            required: false,
            type: Object
        },
        defaultData: {
            required: false,
            type: Object
        },
    },
    async created() {
        await this.loadDefaultData();
        await this.loadLimitedData();
        await this.initializePermissionData();

        if (this.rubricID && !this.dataCache) {
            this.copyParentPermission();
            this.data.parentRight = true;
        }

        if (this.dataCache) {
            this.data = this.dataCache;
            this.data.access_all = this.dataCache.access_all;
            this.data.parentRight = this.dataCache.parentRight;
        }

        if (this.defaultData) {
            this.data.access_all = this.defaultData.access_all;
        }

        this.isBlock = this.rubricID ? this.data.parentRight : this.data.access_all;
        this.isLoaded = true;
    },
    methods: {
        onSubmits(e) {
            e.preventDefault();
            let data = this.dataPreparation();
            session
                .post('/api/v1/permission/info_text/', data)
                .then(response => {
                    data['info_text'] = response.data['text'];
                    data['permissionDataCache'] = this.data;
                    this.$modal.hide('ModalPermissionCreate', {data})
                })
                .catch(() => {
                });
        }
    }
}
</script>

<style lang="scss">
.v--modal-overlay[data-modal="ModalPermissionCreate"] {
    .modal-dialog--access.block {
        margin: 0;
    }
    .close-modal {
        top: -30px;
        right: -30px;
    }
    .v--modal-box {
        padding: 30px;
        overflow-y: scroll;
    }
}
.modal {
    overflow-y: auto;
}
.show-permission {
    display: flex;
    overflow-y: auto !important;
    background: rgba(0, 0, 0, 0.2);
}
.area_lock {
    opacity: 0.5;
    pointer-events: none;
}
.display_flex {
    display: flex !important;
}
.is_invalid {
    border-color: #ef5350;
}

.workers__tree {
    height: auto;
    min-height: 250px;
}

.workers__col {
    height: 100% !important;
}
</style>
